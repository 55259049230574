/*
 * Copyright (C) 2024 Loglama.Net, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import { ViewStore } from 'views/stores/ViewStore';

import QueryGenerator from './queries/QueryGenerator';
import ViewStateGenerator from './views/ViewStateGenerator';

import { QueriesActions } from '../stores/QueriesStore';

export default async () => {
  const { view } = ViewStore.getInitialState();
  const query = QueryGenerator();
  const state = await ViewStateGenerator(view.type);

  return QueriesActions.create(query, state).then(() => query);
};
